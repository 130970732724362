import React, { useState } from 'react';
import { useParams} from 'react-router-dom';

import "./logout.scss"

import Sidebar from '../../components/sidebar/Sidebar'
import LogOut from '../../components/telaAnimada/Logout/logout'


import logo from "./logo.png";


const Logout = () => {

  const {id} = useParams();
 
  return (
    <div className='login'>
      <div className='cabecalho'>

        <div className="navbar" >
          <div className="wrapper">
            <div className="logo">
                <img src={logo} alt="testsss" width={130} height={'auto'} image-rendering={'high-quality'} />
            </div>
          </div>
        </div>

        <Sidebar pagina={""} height={'10px'}/>
      </div>

      <div className="container">
        <div className='quadro'>
          <span className="logo">Taon Cargo</span>
          <br />
          <br />
          <br />


          <div className='coteudo'>

            <LogOut id={id}/>

          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Logout