import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import './app.scss'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import Logout from './pages/logout/Logout'
import Users from './pages/users/Users'
import Settings from './pages/settings/Settings'
import GrandesEncomendas from './pages/grandesEncomendas/GrandesEncomendas'
import Rastreio from "./pages/rastreio/Rastreio"

import { ProtectedLayout } from './components/protectedRoutes/ProtectedLayout'

import { HomeLayout } from './components/protectedRoutes/HomeLayout'
import { ThemeCtx } from './utils/context/themeCtx'

import { ReactQueryDevtools } from 'react-query/devtools'

const App = () => {
  return (
    <div className="app">
      <Routes>
        <Route >
          <Route path="/login/:id" element={<Login />} />
          <Route path="*" element={<Navigate replace to="/" />} />
          <Route path="/rastreio/:id" element={<Rastreio /> }/>
        </Route>

        <Route element={<HomeLayout />}>
          <Route path="*" element={<Navigate replace to="/" />} />

          <Route path="/grandesEncomendas" element={<GrandesEncomendas />} />
          <Route path="/home" element={<Home />} />
          <Route path="/logout/:id" element={<Logout />} />

          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
