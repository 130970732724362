import './input.scss'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';

const Input = (props) => {

    const formatarCPF = (cpf) => {
        // Remove todos os caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');
      
        // Aplica a máscara
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      
        return cpf;
    };

    const handleChange = (event) => {
        const value = event
        return formatarCPF(value);
    };
    const handleChangepass = props.onChange || (() => {});

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };


    return (
        <div className="input" style={{width: props.width, height: props.height, tipo: props}}>
            { props.tipo === "texto" &&
                <input type="text"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }

            { props.tipo === "teclado-numero" &&
                <input type="tel"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }
            { props.tipo === "textoArea" &&
                <textarea 
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }
            {props.tipo === "cpf" &&
                <input 
                type="tel" 
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(handleChange(e.target.value))}
                placeholder={props.placeholder} 
                className="placeholder" 
                maxLength="14"
                />
            }
            {props.tipo === "password" &&
                <div className="password-input-container" style={{display:'flex'}}>
                    <input
                        type={showPassword ? "tel" : "password"}
                        name={props?.name}
                        value={props?.value}
                        onChange={(e) => handleChangepass(e?.target?.value)}
                        placeholder={props?.placeholder}
                        className="placeholder"
                        readOnly={props?.readOnly ?? false}
                    />
                    <button type="button" className="eye-icon" style={{border:'none', fontSize:'15px'}} onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityOff  style={{ fontSize:'20px', marginTop:'2px'}} /> : <Visibility  style={{ fontSize:'20px', marginTop:'2px'}}  />}
                    </button>
                </div>
            }
        </div>
    )
}

export default Input

