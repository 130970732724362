import axios from 'axios'

const baseURL = '/users'

const usersApi = axios.create({
  baseURL: baseURL,
})

export const loginUser = async (value) => {
  
    const res = await usersApi.get('/login', {params: {value:value}})
    return res
}

export const loginDoVeiculo = async (value) => {

    const res = await usersApi.post('/loginVeiculo', value)

    return res
}

export const logoutDoVeiculo = async (value) => {

    const res = await usersApi.post('/logoutVeiculo', value)

    return res
}

export const procurarQuilometragem = async ({ queryKey }) => {

    var value = {"data": queryKey[0]}

    const res = await usersApi.get('/procurarQuilometragem', {params: value})

    return res
}

export const procurarQuilometragemLogout = async ({ queryKey }) => {

    var value = {"data": queryKey[0]}

    const res = await usersApi.get('/procurarQuilometragemLogout', {params: value})

    return res
}



  