import React from 'react'
import './sidebar.scss'

const Sidebar = (props) => {

  return (
    <div className="sidebar">
      <div className="top" style={{height: props?.height}}>
        {props.pagina}
      </div>
    </div>
  );
};

export default Sidebar
