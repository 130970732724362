import React from 'react'
import { useOutletContext } from "react-router-dom";

import './home.scss'
import logo from "./logo.png";

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import CanScan from '../../components/canScan/canScan';

const Home = () => {
  const permissao = useOutletContext();

  return (
    <div className="home">
      <div className="homeCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar />
        <div className="content">
          <img className="logo" src={logo} width={800} height={800} style={{opacity: 0.2}}/>
        </div>
      </div>
    </div>
  )
}

export default Home
