import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Oval } from 'react-loader-spinner'; 
import  {useMutation, useQuery} from 'react-query'
import { Icon } from '@mui/material';

import Cores from '../../cores/Cores'
import Button from '../../button/Button'
import PopUp from '../../popUp/PopUp'
import CanScan from '../../canScan/canScan';

import load from "./load.gif";

import "./FinalizaQr.scss";


const FinalizaQr = ({ trigger, setTrigger, volume, qrCode , setQrFim}) => {
    const [step, setStep] = useState(1); 
    const [loader, setLoader] = useState(false);
    const [yes, setYes] = useState(false);
    const [volumeColeta, setVolumeColeta] = useState(1);
    const [codQR, setCodQR] = useState(false);
    const [codigos, setCodigos] = useState([]);
    const [valida, setValida] = useState([]);
    const [objSCan, setobjSCan] = useState(false);
    const [popUpCamera, setPopUpCamera] = useState(false);
    const [reset, setReset] = useState(true);

    const slideVariants = {
        enter: { x: '100%', opacity: 0 },
        center: { x: 0, opacity: 1 },
        exit: { x: '-100%', opacity: 0 },
    };

    const nextStep = () => {

        console.log(codigos[step - 1] )
        if(codigos[step - 1] === ''){
            alert('Escaneie o QR code antes de avançar.')
            return
        }
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const initialStep = () => {
        setStep(1);
    };

    const lidarFechar = () => {
        initialStep()
        setQrFim(0)
        var validaQr = new Array(valida).fill(false);
        setValida(validaQr)
        setTrigger(false)
        initialStep()
    };

    const lidarFinalizar= () => {

        if(valida.every(item => item === true)){
            setTrigger(false)
            initialStep()
            setQrFim(true)
        }else{
            alert('Não é possivel finalizar enquanto todos os Qr Codes não forem validados.')
            return
        }  
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


    useEffect(() => {

        if(codQR === false || trigger=== true){

            var codigo= qrCode
            var tamanho = codigo.length
            
            setCodigos(codigo)

            var validaQr = new Array(tamanho).fill(false);
            setValida(validaQr)
        }


    },[setCodQR, setTrigger, trigger])  

    useEffect(() => {

        const salvaQr = async () => {
            try {
                await delay(2000);
                setYes(true)
                setLoader(false)
                await delay(2000);
                setYes(false)

              /*const response = await fetch("https://jsonplaceholder.typicode.com/todos/1");
              const data = await response.json();
              setDados(data)*/;
            } catch (error) {
              console.error("Erro ao salvar dados:", error);
            }
        };


        if(qrCode.includes(objSCan)){

            var indice = codigos.indexOf(objSCan)

            if(valida[indice] === false){
                var validaQr = valida
                validaQr[indice] = true
                setValida(validaQr)
                setPopUpCamera(false)

                setLoader(true)
                salvaQr()
            }
            /*var adiciona = codigos
            adiciona[step-1] = objSCan.text
            setCodigos(adiciona)*/
        }

    },[objSCan, setobjSCan]) 


    return (
        <PopUp trigger={trigger} setTrigger={setTrigger} fechar={true}>
            <div  className='telaAimada-finalizada' >
                <i className="material-icons" onClick={() => {lidarFechar()}}>close</i>
            
                <div className="popUp-tela-geral-finalizada">
                    <div className='div-abraca'>


                        <AnimatePresence mode="wait">
                            {step === 1 && !loader &&  !yes && (
                                <motion.div
                                key="step1"
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={slideVariants}
                                transition={{ duration: 0.5 }}
                                className="conteudo"
                                >
                                    <div className="div-step-1">

                                        <div >
                                            {
                                                qrCode.map((item, index)=>{
                                                    
                                                    var color = valida[index] !== false ? 'rgb(66, 175, 60)': '#6B4380'
                                                    return (
                                                        <div key={index} style={{border:'1px solid '+ color, background: color, borderRadius: '5px', padding:'5px', color:'white', marginBottom:'5px'}}>
                                                            {'OBJETO ' + (index+1) + ': ' + qrCode[index]}

                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {loader && (
                                <motion.div
                                    key="step2"
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    variants={slideVariants}
                                    transition={{ duration: 0.5 }}
                                    className="conteudo"
                                >
                                    <div className='confirma'>
                                        <div className="div-step-1">
                                            <div className="loader">
                                                <img className="icone" src={load} />
                                            </div>

                                        </div>
                                    </div>
                                </motion.div>
                            )}


                            {yes && (
                                <motion.div
                                    key="step1"
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    variants={slideVariants}
                                    transition={{ duration: 0.5 }}
                                    className="conteudo"
                                >
                                    <div className='confirma'>
                                        <div className="div-step-1">
                                            <div className="yes">
                                                <Icon  className="icon">select_check_box</Icon>
                                            </div>

                                        </div>
                                    </div>
                                </motion.div>
                            )}

                        </AnimatePresence>

                    <div className="botoes">
                        {step === 1 &&  !loader &&  !yes &&(
                            <div className='botoes-credito'>
                                <div style={{ display:'flex', width:'100%', cursor:"pointer", justifyContent:'center',fontSize:'55px'}}>
                                    <Icon  onClick={() => {setPopUpCamera(true)}} style={{color:"#6B4380", cursor:"pointer", fontSize:'55px'}} >qr_code</Icon>

                                </div>
                                <Button
                                    onClick={ ()=>{lidarFinalizar()}}
                                    width="100px"
                                    color="#ffffff"
                                    background="rgb(66, 175, 60)"
                                    border="rgb(66, 175, 60)"
                                    text="Finalizar"
                                />
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>

            <PopUp trigger={popUpCamera} setTrigger={setPopUpCamera}>
                <div className='popUpConteudoScan'>
                    <div className="div-step-1">
                        <div className="bloco-camera">
                            <CanScan setData={setobjSCan} reset={reset}/>
                        </div>
                    </div>
                </div>
            </PopUp>
        </PopUp>
    );
};

export default FinalizaQr;
