import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Oval } from 'react-loader-spinner'; 
import  {useMutation, useQuery} from 'react-query'
import { Icon } from '@mui/material';

import Cores from '../../cores/Cores'
import Button from '../../button/Button'
import PopUp from '../../popUp/PopUp'
import CanScan from '../../canScan/canScan';

import load from "./load.gif";

import "./ColetaQr.scss";


const ColetaQr = ({ trigger, setTrigger, volume, setQrColeta }) => {
    const [step, setStep] = useState(1); 
    const [loader, setLoader] = useState(false);
    const [yes, setYes] = useState(false);
    const [volumeColeta, setVolumeColeta] = useState(1);
    const [codQR, setCodQR] = useState(false);
    const [codigos, setCodigos] = useState([]);
    const [objSCan, setobjSCan] = useState(false);

    const slideVariants = {
        enter: { x: '100%', opacity: 0 },
        center: { x: 0, opacity: 1 },
        exit: { x: '-100%', opacity: 0 },
    };

    const nextStep = () => {

        if(codigos[step - 1] === ''){
            alert('Escaneie o QR code antes de avançar.')
            return
        }
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const initialStep = () => {
        setStep(1);
    };

    const lidarFechar = () => {

        setCodigos([])
        setQrColeta(0)
        setTrigger(false)
        initialStep()
    };

    const lidarFinalizar= () => {
        setCodigos([])
        setTrigger(false)
        initialStep()
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {

        if(codQR === false || trigger=== true){
            var vetQr = new Array(parseInt(volume)).fill({});
            setCodQR(vetQr)

            var codigo= new Array(parseInt(volume)).fill('');
            
            setCodigos(codigo)
        }


    },[setCodQR, setTrigger, trigger])  

    useEffect(() => {
        const salvaQr = async (qr) => {
            try {
                await delay(2000);
                setYes(true)
                setLoader(false)
                await delay(2000);
                nextStep()
                setYes(false)


                if(step  === parseInt(volume)){

                    lidarFinalizar()
                    setQrColeta(adiciona)
                }

            } catch (error) {
              console.error("Erro ao salvar dados:", error);
            }
        };


        var adiciona = codigos

        if(adiciona[step-1] === '' && !codigos.includes(objSCan) && objSCan !== undefined && objSCan !== null  && typeof objSCan === "string"){


            var adiciona = codigos
            adiciona[step-1] = objSCan
            setCodigos(adiciona)

            if(step  === parseInt(volume)){
                setLoader(true)

                salvaQr(objSCan)

            }else{
                setLoader(true)

                salvaQr(objSCan)
            }
        }



        /*console.log(objSCan)
        if(codigos.includes(objSCan.text) && codigos.indexOf(objSCan.text) < step-1){
            setobjSCan(false)
            alert('Este código já foi registrado.')


        }*/
    },[objSCan]) 

    return (
        <PopUp trigger={trigger} setTrigger={setTrigger} fechar={true}>
            <div  className='telaAimada' >
                <i className="material-icons" onClick={() => {lidarFechar()}}>close</i>
            
                <div className="popUp-tela-geral">
                    <div className='div-abraca'>


                        <AnimatePresence mode="wait">
                            {step === 1 && !loader &&  !yes && (
                                <motion.div
                                    key="step1"
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    variants={slideVariants}
                                    transition={{ duration: 0.5 }}
                                    className="conteudo"
                                >
                                    <div className="div-step-1">
                                        <div className="texto-titulo">{'Coleta ' + volumeColeta}</div>

                                        <div className="bloco-camera">
                                            <CanScan setData={setobjSCan}/>
                                        </div>

                                    </div>
                                </motion.div>
                            )}

                            {loader && (
                                <motion.div
                                    key="step2"
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    variants={slideVariants}
                                    transition={{ duration: 0.5 }}
                                    className="conteudo"
                                >
                                    <div className="div-step-1">
                                        <div className="loader">
                                            <img className="icone" src={load} />
                                        </div>

                                    </div>
                                </motion.div>
                            )}


                            {yes && (
                                <motion.div
                                    key="step3"
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    variants={slideVariants}
                                    transition={{ duration: 0.5 }}
                                    className="conteudo"
                                >
                                    <div className="div-step-1">
                                        <div className="yes">
                                            <Icon  className="icon">select_check_box</Icon>
                                        </div>

                                    </div>
                                </motion.div>
                            )}

                            {codQR && !loader &&  !yes &&
                                codQR
                                .filter((_, index) => index + 1 > 1) 
                                .map((obj, index) => {
                                    return (
                                        <div key={index}>
                                            {step === (index + 2) && ( 
                                                <motion.div
                                                    key={`step-${index + 2}`}
                                                    initial="enter"
                                                    animate="center"
                                                    exit="exit"
                                                    variants={slideVariants}
                                                    transition={{ duration: 0.5 }}
                                                    className="conteudo"
                                                >
                                                    <div className="div-step-1">
                                                        <div className="texto-titulo">{'Coleta ' + step}</div>

                                                        {loader && (
                                                            <div className="loader">
                                                                <img className="icone" src={load} />
                                                            </div>
                                                        )}

                                                        {!loader && (
                                                            <div className="bloco-camera">
                                                                <CanScan setData={setobjSCan}/>
                                                            </div>
                                                        )}

                                                    </div>
                                                </motion.div>
                                            )}
                                        </div>
                                    );

                                })
                            }

                        </AnimatePresence>

                    </div>
                </div>
            </div>
        </PopUp>
    );
};

export default ColetaQr;
