import { useEffect, useState, useRef } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";

import './CanScan.scss'

const CanScan = ({setData, reset}) => {
  const videoRef = useRef(null);
  const codeReaderRef = useRef(null);
  const [result, setResult] = useState("");
  const [scanned, setScanned] = useState(false);

  useEffect(() => {


    const codeReader = new BrowserMultiFormatReader();
    codeReaderRef.current = codeReader;
    codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result, error, controls) => {
      if (result) {

        const bip = new Audio('/sounds/bip.mp3');
        bip.play();
        console.log(result.getText());
        setData(result.getText());
        setScanned(true);
    
        controls.stop();
    
        setTimeout(() => {
          setScanned(false);
          setData(null);
    
          // Reinicia a leitura
          codeReader.decodeFromVideoDevice(undefined, videoRef.current, (res, err, newControls) => {
            if (res) {
              console.log(res.getText());
              setData(res.getText());
              setScanned(true);
            }
            if (err && err.name !== "NotFoundException") {
              console.error("Erro no scanner:", err);
            }
          });
        }, 3000);
      }
    
      if (error && error.name !== "NotFoundException") {
        console.error("Erro no scanner:", error);
      }
    });            

    return () => {
      if (codeReaderRef.current) {
        setData(false);
      }
      if (videoRef.current && videoRef.current.srcObject) {
        let stream = videoRef.current.srcObject;
        let tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    };
  }, []);  

    return (
      <div className="scannet-todo-conteudo">
        <div className="scanner-container">
            <video ref={videoRef} className="scanner-video" autoPlay />
            <div className='overlay'>
                <div className={`scanner-box ${scanned ? "scanned" : ""}`}>
                <div></div>
                </div>
            </div>
            
        </div>
        </div>
    );
};

export default CanScan;
