import React, {useState, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'

import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import { Icon } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Oval } from  'react-loader-spinner'
import { Badge } from '@mui/material';

import Cores from '../../cores/Cores';
import PopUp from '../../popUp/PopUp';
import Button from '../../button/Button'
import WebCam from '../../webCam/WebCam'
import SignaturePad from '../../assinatura/Assinatura'
import Input from '../../input/Input'
import ColetaQr from '../../telaAnimada/GEColetaQr/coletaQr'
import FinalizaQr from '../../telaAnimada/GEFinalizadas/finalizaQr';

import {atualizaGrandesEncomendas, atualizaEntregaGrandesEncomendas, salvaObsGrandesEncomendas, reagendarGrandesEncomendas } from '../../../api/grandesEntregasData'

import './cardList.scss'

export default function CardList({user, lista, titulo, atualiza, placa}) {
  const queryClient = useQueryClient()

  const [conteudoPopUpEntregas, setConteudoPopUpEntregas] = useState("");  
  const [itemCardSelecionado, setItemCardSelecionado] = useState("");  
  const [popUpAbreEntrega, setPopUpAbreEntrega] = useState(false);
  const [popUpAssinatura, setPopUpAssinatura] = useState(false);
  const [popUpObs, setPopUpObs] = useState(false);
  const [popUpReenviar, setPopUpReenviar] = useState(false);
  const [popUpImagens, setPopUpImagens] = useState(false);
  const [inputOBS, setInputOBS] = useState("");
  const [entrega, setEntrega] = useState("");
  const [corQrColeta, setCorQrColeta] = useState("#6B4380");
  const [corQrFinalizada, setCorQrFinalizada] = useState("#6B4380");
  const [loaderInit, setLoaderInit] = useState(true);

  const [popUpCamera, setPopUpCamera] = useState(false);
  const [coletaQr, setColetaQr] = useState(false)
  const [coletaFimQr, setColetaFimQr] = useState(false)
  const [volume, setVolume] = useState(0)
  const [numVolume, setNumVolume] = useState(0);
  const [qrCode, setQrCode] = useState([]);
  const [qrColeta, setQrColeta] = useState(0);
  const [qrFim, setQrFim] = useState(0);

  const [fields, setFields] = useState([]);

  const [loader, setLoader] = useState(false);

  const [img, setImg] = useState([]);
  const [imgAssinatura, setImgAssinatura] = useState([]);
  const [imagemFinalizada, setImagemFinalizada] = useState("");

  const [dadosRecebedor, setDadosRecebedor] = useState("");
  const [historico, setHistorico] = useState("");

  const camposEntrega = [
    { label: 'Empresa:', name: 'nome_cliente_remetente'},
    { label: 'Origem:', name: "origem"},
    { label: 'Destino:', name: 'destino'},
    { label: 'Nota fiscal:', name: 'n_nota_fiscal'}
  ]

  const opcoesLocalizacao = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const opcoesLocalizacaoHora = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',      
    minute: '2-digit',
  };

  const incrementar = () => setNumVolume(numVolume + 1);
  const decrementar = () => setNumVolume(numVolume > 0 ? numVolume - 1 : 0);
  const handleChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    setNumVolume(value);
  };

  const lidarObs = () => {
    if(inputOBS === ""){
      alert("Escrava uma observação para salvar.")
      return
    }

    setLoader(true)

    salvarObservacao.mutate({"obs_reenvio": inputOBS, "id": entrega["_id"]});
  }

  const salvaObj = (obj, popUp) => {

    setEntrega(obj)

    if(popUp === "obs"){
      setPopUpObs(true)

    }else if(popUp === "reenvia"){
      setPopUpReenviar(true)
    }
  }

  const lidarReagendar = () => {
    setLoader(true)

    reeagendar.mutate({"id": entrega["_id"]});
  }

  const handleAssinatura = () => {

    if(imgAssinatura.length > 0){
      alert("É possivel salvar apenas uma assinatura. Por favor, exclua a assinatura registrada para salvar outra.")
      return
    }

    setPopUpAssinatura(true)
  }

  
  const lidarRegistroQr = (volume) => {
    setVolume(volume)
    setColetaQr(true)

  }

    
  const lidarQrColeto = (volume) => {
    setVolume(volume)
    setColetaFimQr(true)

  }

  const handleFinalizadas = (imagem) => {

    //console.log(itemCardSelecionado)
    if(imagem === "assinatura"){

      setImagemFinalizada(itemCardSelecionado["assinatura_destinatario"])
      setHistorico("")
      setDadosRecebedor(itemCardSelecionado["dados_recebedor"])

    }else if(imagem === "fotoColeta"){
      setDadosRecebedor("")
      setHistorico("")
      setImagemFinalizada(itemCardSelecionado["imagem_coleta"])

    }else if(imagem === "fotoEntrega"){
      setImagemFinalizada(itemCardSelecionado["imagem_entrega"])
      setDadosRecebedor("")
      setHistorico("")

    }else if(imagem ==="historico"){
      setDadosRecebedor("")
      setImagemFinalizada("")
      setHistorico(itemCardSelecionado["obs_reenvio"])
    }

    setPopUpImagens(true)
  }

  const handleExcluirImagem = (index, tipo) => {

    if(tipo === "foto"){
        const novaListaImagens = [...img];
        novaListaImagens.splice(index, 1);
        setImg(novaListaImagens);
    }else if(tipo === "assinatura"){
        const novaListaImagensAssinatura = [...imgAssinatura];
        novaListaImagensAssinatura.splice(index, 1);
        setImgAssinatura(novaListaImagensAssinatura);
    }
  };

  const lidarConteudoPopUpEntregas = (item) =>{
    setQrColeta(0)
    setCorQrFinalizada(0)

    var dados = {"id_da_empresa": item.cliente_remetente["id_da_empresa"], 'nome_cliente_remetente': item.cliente_remetente["nome_cliente_remetente"], "volume": item["volume"], "origem": item.cliente_remetente["endereco_remetente"], "destino": item.cliente_destinatario["endereco_parada"], "n_nota_fiscal": item["n_nota_fiscal"]}
    var codigo = item["_id"]
    setConteudoPopUpEntregas({"codigo":codigo, "dados": dados})
    
    setNumVolume(  parseInt(item["volume"]))
    setQrCode(item.qr_code)
    setItemCardSelecionado(item)

    setPopUpAbreEntrega(true)
    return item
  }

  const lidarRegistroEntrega = (status) =>{
    if(status === 'coleta'){
      if(img.length === 0 || qrColeta === 0){
        alert("Nenhuma foto foi identificada ou QR codes não registrados. Por favor, registre uma foto da coleta.")
        return
      }
  
      setLoader(true)
      const data = {
        dados_coleta: itemCardSelecionado,
        imagem: img,
        userLog: user,
        placa: placa,
        qrCode: qrColeta
      };
  

      if(parseInt(conteudoPopUpEntregas.dados.volume) !== numVolume){
        data['volume'] = numVolume
      }
     
      const formDataObject = new FormData();
      formDataObject.append('dados', JSON.stringify(data));
  
      registraEntrega.mutate(formDataObject);
      
    } else if(status === 'finalizar'){

      if(img.length === 0 || imgAssinatura.length === 0){
        alert("Por favor verifique se há registros de fotos e assinatura.")
        return
      }
  
      setLoader(true)
      const data = {
        dados_coleta: itemCardSelecionado,
        imagem: img,
        assinatura: imgAssinatura,
        dados_recebedor : {"cpf": fields[1]["value"], "nome": fields[0]["value"]},
      };
  
      const formDataObject = new FormData();
      formDataObject.append('dados', JSON.stringify(data));
  
      finalizaEntrega.mutate(formDataObject);
    }

    atualiza(true)
  }

  const reeagendar = useMutation(reagendarGrandesEncomendas, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      if (e.status === 201) {
        setLoader(false)
        alert("Corrida enviada com sucesso.")
        setEntrega("")
        setPopUpReenviar(false)
      }
    },

    onError: (e) => {
      setLoader(false)
      alert(e.response.data)
      setEntrega("")
      setPopUpReenviar(false)
    },

  })

  const salvarObservacao = useMutation(salvaObsGrandesEncomendas, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      if (e.status === 201) {
        setLoader(false)
        alert("Observação salva com sucesso.")
        setInputOBS("")
        setEntrega("")
        setPopUpObs(false)
      }
    },

    onError: (e) => {
      setLoader(false)
      setInputOBS("")
      setEntrega("")
      setPopUpObs(false)
      alert(e.response.data)
    },

  })

  const finalizaEntrega = useMutation(atualizaEntregaGrandesEncomendas, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      if (e.status === 201) {
        setLoader(false)
        setImg([])
        setImgAssinatura([])
        setPopUpAbreEntrega(false)
        setConteudoPopUpEntregas("")
        setItemCardSelecionado("")
        alert(e.data)
      }
    },

    onError: (e) => {
      setLoader(false)
      setImg([])
      setImgAssinatura([])
      setPopUpAbreEntrega(false)
      setConteudoPopUpEntregas("")
      setItemCardSelecionado("")
      alert(e.response.data)
    },
  });

  const registraEntrega = useMutation(atualizaGrandesEncomendas, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      if (e.status === 201) {
        setLoader(false)
        setImg([])
        setPopUpAbreEntrega(false)
        setConteudoPopUpEntregas("")
        setItemCardSelecionado("")
        setQrColeta(0)
        alert(e.data)
      }
    },

    onError: (e) => {
      setLoader(false)
      setImg([])
      setPopUpAbreEntrega(false)
      setConteudoPopUpEntregas("")
      setItemCardSelecionado("")
      setQrColeta(0)
      alert(e.response.data)
    },
  });

  useEffect(() => {

    if(popUpAbreEntrega === false){
      setImg([])
      setImgAssinatura([])
    }
    
  }, [setPopUpAbreEntrega, popUpAbreEntrega])

  useEffect(() => {

    if(qrColeta !== 0){
      setCorQrColeta('rgb(66, 175, 60)')

    }else{
      setCorQrColeta('#6B4380')

    }
    
  }, [qrColeta, setQrColeta])

  useEffect(() => {

    if(qrFim !== 0){
      setCorQrFinalizada('rgb(66, 175, 60)')

    }else{
      setCorQrFinalizada('#6B4380')

    }
  
  }, [qrFim, setQrFim])

  return (
    <div className='capa-card-list' e={{ }}>
      <div className='titulo-card'>
      </div>
      <div className='capa-conteudo-card'>
        <div className='conteudo-card'>
          {lista &&
            Array.isArray(lista) &&
              lista.map((obj, indice) => (
                  <Card key={indice} sx={{ margin: '10px  0px 10px 10px', width: '350px', height: '120px', maxHeight:'200px', overflowY: 'hidden'}}>
                    <CardActionArea  onClick={()=>{lidarConteudoPopUpEntregas(obj)}} >
                      <CardContent style={{display:"flex", justifyContent:'space-between'}}>
                        <div >
                          <Typography gutterBottom variant="p4" component="div" style={{height: 'auto'}} />
                          <Chip label={`ID: ${obj["_id"].substring(obj["_id"].length - 4)}`} variant="outlined" color="primary" style={{ height: "auto", marginLeft: "auto", marginRight:"5px" }}/>  
                          <Chip label={`${obj["cliente_remetente"]["nome_cliente_remetente"]}`} variant="outlined" color="primary" style={{ height: "auto", marginLeft: "auto" }}/>  

                          <Typography gutterBottom variant="p4" component="div" style={{height: 'auto'}} />
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon style={{ cursor:"pointer", marginRight: "3px" }} className="icon-card-list">business</Icon>
                            <div style={{ overflow: "hidden", textOverflow: "clip", whiteSpace: "nowrap", maxWidth: "200px", fontSize:"15px"}}>{"De:"}</div>
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", marginLeft:"5px" }}>{obj["cliente_remetente"]["endereco_remetente"]}</div>
                          </div>

                          <Typography variant="body2" color="text.secondary" />
                          <div style={{ display: "flex", height: "auto", color: Cores.cinzaClaro}}>
                            <PlaceOutlinedIcon fontSize="small" style={{ marginRight: "5px" }}/>
                            <div style={{ overflow: "hidden", textOverflow: "clip", whiteSpace: "nowrap", maxWidth: "200px", fontSize:"15px"}}>{"P/:"}</div>
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", marginLeft:"5px" }}>{obj["cliente_destinatario"]["endereco_parada"]}</div>
                          </div>

                          <Typography variant="body2" color="text.secondary" />
                          <div style={{ display: "flex", height: "auto"}}>
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", marginLeft:"5px" }}>{obj["cliente_destinatario"]["cidade_parada"]}</div>
                          </div>
                        </div>

                        <div style={{display:"flex", alignItems: "center", flexDirection: "column", justifyContent: 'space-between'}}>
                           <div style={{marginLeft:"10px", display:"flex"}}>
                              <Icon style={{ cursor:"pointer", marginRight: "5px", fontSize:"15px" }} className="icon-card-list">inventory_2</Icon>
                              <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "200px", marginLeft:"5px" }}>{obj["volume"]}</div>
                            </div>
                          {titulo === "Coletadas" &&
                            <div style={{marginLeft:"10px", display:"flex"}}>
                              <Icon style={{ cursor:"pointer", marginRight: "5px" }} onClick={()=>{salvaObj(obj, "obs")}} className="icon-card-list">menu_book</Icon>
                              <Icon style={{ cursor:"pointer",color:Cores.roxo }} onClick={()=>{salvaObj(obj, "reenvia")}} className="icon-card-list">reply_all</Icon>
                            </div>
                          }
                          {titulo === "finalizadas" &&
                            <div style={{marginLeft:"10px", display:"flex"}}>
                              {
                                new Date(obj["updatedAt"]).toLocaleString('pt-BR', opcoesLocalizacao)
                              }
                            </div>
                          }
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
          ))}
          {!lista &&
              <div className='loader-grandes-encomendas'>
                <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loaderInit} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
          }
        </div>
      </div>

      <PopUp trigger={popUpAbreEntrega} setTrigger={setPopUpAbreEntrega}>
        <div className='popUpConteudo'>
          {loader === true &&
          <div className='centraliza_loader'>
            <div className='loader-card-list'>
              <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
            </div>
            </div>
          }
          <div className='codigo-titulo-popup'>
            <div className='texto-titulo-popup'>
              {conteudoPopUpEntregas !== "" &&
                "Código: " + conteudoPopUpEntregas["codigo"]
              }
            </div>
          </div>

          <div className='dados-popup'>
            { conteudoPopUpEntregas !== "" &&
              camposEntrega.map((item, indice) =>{
                var nome = item.name
                var dados = conteudoPopUpEntregas["dados"]
                return(
                  <div key={indice} className='texto-titulo-popup'>
                    <div className='label'> {item["label"]}</div>
                    <div className='conteudo' > { dados[nome]}</div>
                  </div>
                )
              })
            }
          </div>

          { titulo === "Coletas Solicitadas" && conteudoPopUpEntregas !== "" &&
            <div className='abraca-sec-volume'>
              <div className='sec-volume'>
                <div className='titulo'> Volume:</div>
                <div className="volume-container">
                  <Icon   className="btn" component={RemoveIcon} onClick={decrementar}/>

                  <div style={{fontSize:"35px"}}> {numVolume}</div>
                  
                  <Icon  className="btn" component={AddIcon}  onClick={() => {incrementar()}} />
                </div>
              </div>


              <div className='Qr-code-registra'>
               <Icon onClick={() => {lidarRegistroQr(conteudoPopUpEntregas["dados"]["volume"] )}} style={{ cursor:"pointer", marginLeft: "5px", color: corQrColeta, fontSize:"55px" }}>qr_code</Icon>
              </div>
            </div>
          }

          
        </div>

        <div className='popUpConteudo'>
          <div className='iconesEntrega'>
            <div className='iconePhoto'>
              { titulo === "finalizadas" &&
                <div>
                  <Icon onClick={() => {handleFinalizadas("fotoColeta")}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconCameraEntrega">camera_alt</Icon>
                  <div style={{fontSize:'11px', width:"100%", display:"flex", justifyContent: "center"}}> Coleta</div>
                </div>
              }
              { titulo === "Coletas Solicitadas" && 
                <div className='conteudo'>
                  <Icon  onClick={() => {setPopUpCamera(true)}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconCameraEntrega">camera_alt</Icon>
                  <div className='listaFotos'>
                    {img.map((img_src, index) => (
                      <Badge key={index} color="secondary" overlap="circular" badgeContent="X" onClick={()=>handleExcluirImagem(index, "foto")}>
                        <img className='capitura' src={img_src} alt="webcam" />
                      </Badge>
                    ))}
                  </div>
                </div>
              }
              { titulo === "Coletadas" && 
                <div  className='conteudo'>
                  <Icon  onClick={() => {setPopUpCamera(true)}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconCameraEntrega">camera_alt</Icon>
                  <div className='listaFotos'>
                    {img.map((img_src, index) => (
                      <Badge key={index} color="secondary" overlap="circular" badgeContent="X" onClick={()=>handleExcluirImagem(index, "foto")}>
                        <img className='capitura' src={img_src} alt="webcam" />
                      </Badge>
                    ))}
                  </div>
                </div>
              }

            </div>
            { titulo === "finalizadas" &&
              <div className='iconePhoto'>
                <div>
                  <Icon  onClick={() => {handleFinalizadas("fotoEntrega")}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconCameraEntrega">camera_alt</Icon>
                  <div style={{fontSize:'11px', width:"100%", display:"flex", justifyContent: "center"}}>Entrega</div>
                </div>
              </div>
            }

            { (titulo === "Coletadas" || titulo === "finalizadas") &&
              <div className='iconePhoto'>

                {titulo === "Coletadas" &&
                  <Icon onClick={() => {handleAssinatura()}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconAssinaturaEntrega">draw</Icon>
                }
                {titulo === "finalizadas" &&
                  <div>
                    <Icon onClick={() => {handleFinalizadas("assinatura")}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconAssinaturaEntrega">draw</Icon>
                    <div style={{fontSize:'11px', width:"100%", display:"flex", justifyContent: "center"}}>Assinatura</div>
                  </div>
                }
                
                <div className='listaFotos'>
                  {imgAssinatura.map((img_src, index) => (
                    <Badge key={index} color="secondary" overlap="circular" badgeContent="X" onClick={()=>handleExcluirImagem(index, "assinatura")}>
                      <img className='capitura' src={img_src} alt="webcam" />
                    </Badge>
                  ))}
                </div>
              </div>
            }

            { titulo === "finalizadas" &&
              <div className='iconePhoto'>
                <div>
                  <Icon  onClick={() => {handleFinalizadas("historico")}} style={{color:"rgb(29, 28, 28)", cursor:"pointer"}} className="iconCameraEntrega">description</Icon>
                  <div style={{fontSize:'11px', width:"100%", display:"flex", justifyContent: "center"}}>Observações</div>
                </div>
              </div>
            }
          </div>

          {titulo === "Coletadas" &&

            <div style={{display:'flex', justifyContent:'center'}} className='Qr-code-registra'>
            <Icon onClick={() => {lidarQrColeto(conteudoPopUpEntregas["dados"]["volume"] )}} style={{ cursor:"pointer", marginLeft: "5px", color:corQrFinalizada, fontSize:"55px",  border:'1px solid #BCB0B0', borderRadius:'5px' }}>qr_code</Icon>
            </div>
          }

          { titulo === "Coletas Solicitadas" &&
            <div className='buttonRegistrar'>
              <Button onClick={() => {lidarRegistroEntrega("coleta")}} width={"100px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Coletar"} />
            </div>
          }

          { titulo === "Coletadas" &&
            <div className='buttonRegistrar'>
              <Button onClick={() => {lidarRegistroEntrega("finalizar")}} width={"100px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Finalizar"} />
            </div>
          }
        </div>
    </PopUp>

    <ColetaQr trigger={coletaQr} setTrigger={setColetaQr} volume={numVolume} setQrColeta={setQrColeta}/>

    <FinalizaQr trigger={coletaFimQr} setTrigger={setColetaFimQr} volume={volume} qrCode={qrCode} setQrFim={setQrFim}/>

    <PopUp trigger={popUpCamera} setTrigger={setPopUpCamera}>
      <div className='popUpConteudoCameraFoto'>
        <WebCam setTrigger={setPopUpCamera} setImagem={(src) => setImg([...img, src])}>
        </WebCam>
      </div>
    </PopUp>

    <PopUp trigger={popUpAssinatura} setTrigger={setPopUpAssinatura}>
      <div className='popUpConteudoCameraFoto'>
        <div className='sec-assinatura'>
          <SignaturePad setFields={setFields} setTrigger={setPopUpAssinatura} setImagem={(src) => setImgAssinatura([...imgAssinatura, src])} />
        </div>
      </div>
    </PopUp>

    <PopUp trigger={popUpObs} setTrigger={setPopUpObs}>
      <div className='popUpObs'>
        {loader === true &&
          <div className='loader-card-list'>
            <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
          </div>
        }
        <label style={{marginLeft:"5px"}}>
          Digite uma observação:
        </label>
        <Input value={inputOBS} onChange={setInputOBS} placeholder={"Digite aqui..."} tipo={"textoArea"} height={"50px"}></Input>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
          <Button onClick={() => {lidarObs()}} width={"100px"} color={"#ffffff"} background={Cores.verde} border={Cores.verde} text={"Salvar"} />
        </div>
      </div>
    </PopUp>

    <PopUp trigger={popUpReenviar} setTrigger={setPopUpReenviar}>
      <div className='popUpObs'>
        {loader === true &&
          <div className='loader-card-list'>
            <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
          </div>
        }
        <div style={{margin:"15px"}}>
          Tem certeza que deseja reagendar a entrega?
        </div>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
          <Button onClick={() => {lidarReagendar()}} width={"100px"} color={"#ffffff"} background={Cores.verde} border={Cores.verde} text={"Sim"} />
          <Button onClick={() => {setPopUpReenviar(false)}} width={"100px"} color={"#ffffff"} background={Cores.vermelho} border={Cores.vermelho} text={"Não"} />
        </div>
      </div>
    </PopUp>

    <PopUp trigger={popUpImagens} setTrigger={setPopUpImagens}>

      <div style={{padding:'0px' }}>

        { imagemFinalizada !== "" &&
          imagemFinalizada.map((img_src, index) => (
         
          <img key={index} style={{ 
            width: "100%",
            height: "100%",
            objectFit: "cover", 
            borderRadius:'8px',
            marginTop:'5px'
          }} src={img_src} />))
        }

        {dadosRecebedor !== '' &&
          <div>
            <div>
              {"Nome:  " + dadosRecebedor["nome"]}
            </div>
            <div>
              {"CPF :  " + dadosRecebedor["cpf"]}
            </div>
          </div>
        }

        {historico !== '' &&
          <div className='borda-historico'>
              <div className='cabeca-historico'>
                <div>
                  Observação
                </div>
                <div>
                  Data
                </div>
              </div>
            {historico.map((obj, indice) =>{
              return(
                  <div className='corpo-historico' key={indice}> 
                    <div>
                      {obj.obs}
                    </div>
                    <div>
                      {obj?.data !== undefined &&
                        new Date(obj.data).toLocaleString('pt-BR', opcoesLocalizacaoHora)
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    </PopUp>
    </div>
  );
}
  
