import React from 'react'

import { Icon } from '@mui/material';
import './popUp.scss'


const PopUp = (props) => {
    return (props.trigger) ? (
        <div className={`popup ${props.light ? 'popup-light' : ''}`}>
            <div className='popup-inner' style={props.style}>
               {!props.fechar === true &&
                 <Icon className="icone-popup-fechar" onClick={() => props.setTrigger(false)}>close</Icon>
                }

                <div className="conteudo-popUp-fechar">
                    {props.children}                
                </div>
            </div>
        </div>
    ): "";

}

export default PopUp