import React, { useState, useEffect } from 'react';
import {useMutation, useQueryClient, useQuery} from 'react-query'
import { motion, AnimatePresence } from 'framer-motion';
import { Icon } from '@mui/material';

import Input from '../../input/Input'
import Button from '../../button/Button'
import { useAuth  } from "../../../hooks/useAuth";
import WebCam from '../../webCam/WebCam'
import PopUp from '../../popUp/PopUp';

import {loginUser, loginDoVeiculo, procurarQuilometragem } from '../../../api/usuarioData'

import "./Login.scss"
import load from "./load.gif";


const Login = ({id}) => {

    const [step, setStep] = useState(1); 
    const { login } = useAuth();
    const [ID, setID] = useState("");
    const [km, setKm] = useState("");
    const [kmBanco, setKmBanco] = useState("");
    const [senha, setSenha] = useState("");
    const [cor, setCor] = useState("rgb(29, 28, 28)");
    const [can, setCan] = useState(false);
    const [img, setImg] = useState([]);
    const [loader, setLoader] = useState(false);

    const slideVariants = {
        enter: { x: '100%', y: 0, opacity: 0},
        center: { x: 0, y: 0, opacity: 1 },
        exit: { x: '-100%', y: 0, opacity: 0 },
    };

    const quilometragem = useQuery([{"placa_veiculo": id}], procurarQuilometragem, {
        staleTime: 0, 
        cacheTime: Infinity,
        refetchOnMount: true, 
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    })
      
    const lidarLogin = () => {
            
        if(id !== '' && senha !== ''){
            var dados = {
                id: ID,
                senha: senha,
                placa: id
            }

            loginUsuario.mutate(dados)  
        }
    };

    const lidarLoginVeiculo = () => {

        if(km < kmBanco){
            alert('A quilometragem fornecida é menor que a última registrada.')
            return

        }

        if(km !== '' && img.length > 0){
            var dados = {
                id: ID,
                placa: id,
                km: km,
                img: img
            }
    
            setLoader(true)
            loginVeiculo.mutate(dados)  

        }else{
            alert('Adicione a quilometragem e adicione a imagem do painel do veiculo.')
            return
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const loginVeiculo = useMutation(loginDoVeiculo, {
        onSuccess: (e) => {
            if (e.status === 201) {
                login({ id: ID, placa: id})
            }
        },

        onError: (e) => {
            setLoader(false)
            alert(e.response.data)
        },
    });

    const loginUsuario = useMutation(loginUser, {
        onSuccess: (e) => {
            if (e.status === 201) {
                nextStep()
            }
        },

        onError: (e) => {
            alert('Senha ou Usuario incorretos.')
        },
    });

    useEffect(() => {

        if(img.length !== 0){
            setCor('rgb(66, 175, 60)')
        }
    
    }, [setImg, img])


    useEffect(() => {

        if(quilometragem.isSuccess){
            setKm(quilometragem.data.data)
            setKmBanco(quilometragem.data.data)

        }
    
    }, [quilometragem.isSuccess])
    
    
    return (
        <div className='conteudo-tela-login'>
            <AnimatePresence >
                {step === 1 && (
                    <motion.div
                        key="step1"
                        initial="enter"
                        animate="center"
                        exit="exit"
                        variants={slideVariants}
                        transition={{ duration: 0.6 }}
                        className="conteudo"
                    >
                        <div className="placa" >
                            <div className='escrita'> {id}</div>
                        </div>

                        <div className='quadro-login'>

                        <div>
                            <div style={{height:'90px', display:'flex', flexDirection:'column', justifyContent:'space-between', marginBottom:'20px'}}>
                                <Input tipo="cpf" placeholder="ID do Motorista" border={"1px solid #FAD40C"} width={"192px"} value={ID} onChange={setID}/>
                                <Input tipo="password" placeholder="Senha do Motorista" border={"1px solid #FAD40C"} width={"192px"}  value={senha} onChange={setSenha}/>
                            </div>
                                <Button width={"120px"} color={"#EEECEF"} height='auto' padding="10px 0px" background={'#6B4380'} text={"Entrar"} onClick={() => {lidarLogin()}}/>
                            </div>
                        </div>

                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence mode="wait">
                {step === 2 && (
                    <motion.div
                    initial={{ x: 600, opacity: 0 }} 
                    animate={{ x: 0, opacity: 1 }}   
                    exit={{ x: -100, opacity: 0 }}  
                    transition={{ duration: 0.8 }} 
                    className="conteudo"  
                    >
                        {loader === false &&
                        
                            <div style={{ display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center', marginBottom:'20px', transform:'translate(-50%, -50%);'}}>
                                <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', marginBottom:'10px'}}>
                                    <div style={{marginLeft:'8px', color:'#6B4380', fontWeight:'bold' }}>
                                        Quilometragem
                                    </div>
                                    <Input tipo="teclado-numero" placeholder="Km" border={"1px solid #FAD40C"} width={"192px"} value={km} onChange={setKm}/>
                                </div>
                                <Icon  onClick={() => {setCan(true)}} style={{color: cor, cursor:"pointer", fontSize:'55px'}} className="iconCameraEntrega">camera_alt</Icon>
                                <Button width={"120px"} height='auto'  color={"#EEECEF"} padding="10px 0px" background={'#6B4380'} text={"Avançar"} onClick={() => {lidarLoginVeiculo()}}/>
                            </div>
                        }
                        {loader && (
                            <div className="div-step-1" >
                                <div className="loader">
                                    <img className="icone" src={load} />
                                </div>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
            <PopUp trigger={can} setTrigger={setCan}>
                <div className='popUpCanLogin'>
                    <WebCam setTrigger={setCan} setImagem={(src) => setImg([...img, src])} />
                </div>
            </PopUp>
        </div>
    );

};


export default Login;