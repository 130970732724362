import React from 'react'

import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  //const [userSettings, setuserSettings] = useLocalStorage('userSettings', null)
  //const [userData, setUserData] = useLocalStorage("userData", null);

  const navigate = useNavigate()

  const login = async (data) => {

    let userData = data
    setUser(userData);
    navigate("/grandesEncomendas", { replace: true });
  }

  const logout = (placa) => {
    navigate('/login/'+placa, { replace: true })
    setUser(null)
  }

  const value = useMemo(
    () => ({
      user,
      //userData,
      login,
      logout
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
